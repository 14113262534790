import { useState, useEffect, useRef } from "react";

const useSticky = () => {
    const [headerHeight, setHeaderHeight] = useState(0);
    const [sticky, setSticky] = useState(false);
    const headerRef = useRef(null);
    const stickyHeight = useRef(0);

    useEffect(() => {
        setHeaderHeight(headerRef.current.clientHeight);
        stickyHeight.current = headerRef.current.clientHeight * 2;
    }, []);

    useEffect(() => {
        const scrollHandler = () => {
            const scrollPos = window.scrollY;
            if (scrollPos > stickyHeight.current) {
                setSticky(true);
            }

            if (scrollPos < stickyHeight.current) {
                setSticky(false);
            }
        };

        window.addEventListener("scroll", scrollHandler);
        return () => {
            window.removeEventListener("scroll", scrollHandler);
        };
    }, [sticky, stickyHeight]);

    return { sticky, headerRef, headerHeight };
};

export default useSticky;
