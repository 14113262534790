/** @jsx jsx */
import { jsx } from "theme-ui";
import { memo } from "react";
import PropTypes from "prop-types";
import SearchForm from "../forms/search-form";
import { StyledSearchContent } from "./style";

const SearchFlyout = ({ show }) => (
    <StyledSearchContent show={show}>
        <SearchForm />
    </StyledSearchContent>
);

SearchFlyout.propTypes = {
    show: PropTypes.bool.isRequired,
};

export default memo(SearchFlyout);
