import styled from "@boseo/shared/styled";

export const StyledInfo = styled.div`
    p {
        margin-bottom: 9px;
        a {
            text-decoration: underline;
        }
    }
`;
