/** @jsx jsx */
import { jsx } from "theme-ui";
import PropTypes from "prop-types";
import {
    Accordion as AccordionComponent,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from "react-accessible-accordion";
import cn from "classnames";
import { ItemType } from "@boseo/helper/types";
import { StyledAccordion } from "./style";

const Accordion = ({
    className,
    items = [],
    allowZeroExpanded,
    sx,
    space,
    layout,
}) => (
    <StyledAccordion
        className={cn("accordion-wrap", className)}
        sx={sx}
        space={space}
        layout={layout}
    >
        <AccordionComponent
            allowZeroExpanded={allowZeroExpanded}
            preExpanded={[`${items?.[0]?.id}`]}
        >
            {items.map(({ id, title, description }) => (
                <AccordionItem key={id} uuid={`${id}`}>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            {title} <span className="icon" />
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>{description}</AccordionItemPanel>
                </AccordionItem>
            ))}
        </AccordionComponent>
    </StyledAccordion>
);

Accordion.propTypes = {
    className: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape(ItemType)).isRequired,
    allowZeroExpanded: PropTypes.bool,
    space: PropTypes.string,
    layout: PropTypes.oneOf([1, 2]),
    sx: PropTypes.shape({}),
};

Accordion.defaultProps = {
    allowZeroExpanded: true,
    layout: 1,
};

export default Accordion;
