/** @jsx jsx */
import { jsx } from "theme-ui";
import PropTypes from "prop-types";
import {
    BlogMedia,
    BreadCrumb02,
    BlogMeta,
    BlogAuthor,
    BlogComment,
} from "gatsby-theme-component";
import { ImageType, SocialType, BlogFormatType } from "@boseo/helper/types";
import {
    StyledSection,
    StyledBlogWrap,
    StyledBlogContent,
    StyledBlogSidebar,
    StyledTitle,
    StyledBody,
    StyledFooter,
    StyledTag,
    StyledShare,
    StyledSocialLink,
} from "./style";

const BlogDetails = ({ data }) => (
    <StyledSection>
        <div className="container">
            <BlogMedia
                title={data.title}
                format={data.format}
                image={data.image}
                video_link={data.video_link}
                gallery_images={data.gallery_images}
                isDetails
                sx={{ mb: ["40px", null, "50px", "80px"] }}
            />
            <StyledBlogWrap>
                <StyledBlogContent>
                    <BreadCrumb02
                        prev={[
                            { text: "home", link: "/" },
                            { text: "blog", link: "/blog" },
                        ]}
                        title={data.title}
                        sx={{ mb: ["30px", null, null, "40px"] }}
                    />
                    {data?.title && <StyledTitle>{data.title}</StyledTitle>}
                    <BlogMeta
                        sx={{ mb: ["30px", null, null, "50px"] }}
                        metaList={[
                            {
                                text: "By",
                                title: data?.author?.username,
                                path: data?.author?.slug,
                            },
                            {
                                title: data?.date,
                            },
                            {
                                text: "In",
                                title: data?.category?.title,
                                path: data?.category?.slug,
                            },
                        ]}
                    />
                    <StyledBody
                        dangerouslySetInnerHTML={{
                            __html: data?.content || "Blog Content",
                        }}
                    />
                    <StyledFooter>
                        <div className="tags">
                            {data?.tags?.map((tag) => (
                                <StyledTag key={tag.slug} path={tag.slug}>
                                    {tag.title}
                                </StyledTag>
                            ))}
                        </div>
                        <StyledShare>
                            <span>Share On: </span>
                            <StyledSocialLink
                                href="https://facebook.com"
                                sx={{ color: "facebook" }}
                            >
                                <i className="fa fa-facebook" />
                            </StyledSocialLink>
                            <StyledSocialLink
                                href="https://facebook.com"
                                sx={{ color: "twitter" }}
                            >
                                <i className="fa fa-twitter" />
                            </StyledSocialLink>
                            <StyledSocialLink
                                href="https://facebook.com"
                                sx={{ color: "dribbble" }}
                            >
                                <i className="fa fa-dribbble" />
                            </StyledSocialLink>
                            <StyledSocialLink
                                href="https://facebook.com"
                                sx={{ color: "pinterest" }}
                            >
                                <i className="fa fa-pinterest" />
                            </StyledSocialLink>
                        </StyledShare>
                    </StyledFooter>
                    <BlogComment
                        slug={data.slug}
                        title={data.title}
                        siteUrl={data.siteUrl}
                    />
                </StyledBlogContent>
                <StyledBlogSidebar>
                    {data?.author && (
                        <BlogAuthor
                            name={data.author?.username}
                            designation={data.author?.designation}
                            avatar={data.author?.avatar}
                            bio={data.author?.bio}
                            socials={data.author?.socials}
                            path={data.author?.slug}
                        />
                    )}
                </StyledBlogSidebar>
            </StyledBlogWrap>
        </div>
    </StyledSection>
);

BlogDetails.propTypes = {
    data: PropTypes.shape({
        siteUrl: PropTypes.string,
        title: PropTypes.string,
        slug: PropTypes.string,
        format: BlogFormatType,
        video_link: PropTypes.string,
        gallery_images: PropTypes.arrayOf(PropTypes.shape(ImageType)),
        image: PropTypes.shape(ImageType),
        author: PropTypes.shape({
            username: PropTypes.string,
            slug: PropTypes.string,
            designation: PropTypes.string,
            avatar: PropTypes.shape(ImageType),
            bio: PropTypes.string,
            socials: PropTypes.arrayOf(PropTypes.shape(SocialType)),
        }),
        date: PropTypes.string,
        category: PropTypes.shape({
            title: PropTypes.string,
            slug: PropTypes.string,
        }),
        tags: PropTypes.arrayOf(
            PropTypes.shape({
                title: PropTypes.string,
                slug: PropTypes.string,
            })
        ),
        content: PropTypes.string,
    }),
};

export default BlogDetails;
